body {
  position: absolute;
}

#textBlock {
  text-align: center;
  margin-top: 5%;
  padding-left: 30%;
  padding-right: 30%;
}

#head {
  font-size: 9vw;
  margin-bottom: 0;
}

#text {
  margin-top: 0;
  font-size: 2vw;
}

#image {
  width: 20%;
  display: block;
  margin-top: 5%;
  margin-left: 40%;
}

@media (max-width: 575.99px) {
  #textBlock {
    padding-right: 0;
    padding-left: 0;
  }

  #head {
    font-size: 15vw;
  }

  #text {
    font-size: 4vw;
  }

  #image {
    width: 70%;
    margin-left: 15%;
  }
}

/* @font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: normal;
  src: local('SourceCodePro'), url('../fonts/SourceCodePro-Regular.otf');
}

@font-face {
  font-family: 'Caviar Dreams';
  font-style: normal;
  font-weight: normal;
  src: local('CaviarDreams'), url('../fonts/CaviarDreams.ttf') format('truetype');
}

body {
  background-image: url("../images/404bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
}

#textblock {
  width: 45%;
  margin: 0 auto;
  margin-left: 50%;
  margin-top: 15%;
}

h1 {
  font-family: 'Caviar Dreams';
  font-size: 9vw;
}

h2 {
  font-family: 'Source Code Pro';
  font-size: 2vw;
  background: #ffffff;
}

#button-home {
  font-size: 1vw;
  padding: 8px;
  width: 30%;
  margin: 2%;
  border: 3px solid #0DC7A3;
  padding: 5px;
  color: #0DC7A3;
  background-color: rgba(0, 0, 0, 0);
  background: #ffffff;
}

#button-home:hover {
  color: white !important;
  background-color: #0DC7A3 !important;
  padding: 8px;
  border: none;
} */
